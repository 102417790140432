import React, { Component } from "react";
import Header from "../header";
import Layout from "../layout";
import Milestone from "../milestone";
import "./style.scss";
import { Link } from "gatsby";

import milestone1 from "../../images/awards/remade/franchise india award for best Architecture.jpg";
import milestone2 from "../../images/awards/remade/blind wink award for the most innovative interior designer.jpg";
import milestone3 from "../../images/awards/remade/coverage in retailer magagzine.jpg";
import milestone4 from "../../images/awards/remade/society interiors publication August 2015.jpg";
import milestone5 from "../../images/awards/remade/Architecture _ Designs Studio.jpg";
import SEO from "../seo";

const milestone6 =
  "https://archdesignsstudio.com/assets/milestones/interiorexterior2019.jpg";

const milestone1mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/franchise_india_award.jpg";
const milestone3mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/retailer_magazine.jpg";
const milestone2mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/wink_award.jpg";
const milestone4mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/society_interiors_2015.jpg";
const milestone5mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/wbrcorp.jpg";
const milestone6mob =
  "https://archdesignsstudio.com/assets/milestones/mobile/interiorexterior2019mob.jpg";

function Milestones() {
  const desktopView = (
    <>
      {/* <Milestone
        title="Coverage in Interior Exterior Magazine, 2019"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone6}
      /> */}
      <Milestone
        title="Franchise India Award for best Architecture - Residential
                    Category, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone1}
        isGray
      />
      <Milestone
        title="Coverage in Retailer Magazine, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone3}
      />
      <Milestone
        title="WBR Corp Award for the Best Institutional Architecture, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone5}
        isGray
      />
      <Milestone
        title="Blind Wink Award for the Most Innovative Interior Designer, 2017"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone2}
      />
      <Milestone
        title="Society Interiors Publication August, 2015"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone4}
        isGray
      />
    </>
  );

  const mobileView = (
    <>
      {/* <Milestone
        title="Coverage in Interior Exterior Magazine, 2019"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone6mob}
      /> */}
      <Milestone
        title="Franchise India Award for best Architecture - Residential
                    Category, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone1mob}
        isGray
      />
      <Milestone
        title="Coverage in Retailer Magazine, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                    exercitation nulla anim laboris veniam nulla. Qui laboris
                    sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                    reprehenderit excepteur incididunt."
        img={milestone3mob}
      />
      <Milestone
        title="WBR Corp Award for the Best Institutional Architecture, 2018"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
        exercitation nulla anim laboris veniam nulla. Qui laboris
        sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
        reprehenderit excepteur incididunt."
        img={milestone5mob}
        isGray
      />
      <Milestone
        title="Blind Wink Award for the Most Innovative Interior Designer, 2017"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
                      exercitation nulla anim laboris veniam nulla. Qui laboris
                      sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
                      reprehenderit excepteur incididunt."
        img={milestone2mob}
      />
      <Milestone
        title="Society Interiors Publication August, 2015"
        description=" Aliquip in laborum labore enim pariatur eu proident aliquip
        exercitation nulla anim laboris veniam nulla. Qui laboris
        sint ad aliqua. Dolor eu tempor id ut dolore ex Lorem nisi
        reprehenderit excepteur incididunt."
        img={milestone4mob}
        isGray
      />
    </>
  );

  return (
    <Layout>
      <SEO
        title="A&D Studio | Awards & Milestones"
        description="Architecture & Design Studios is an award-winning, multi-disciplinary firm with its headquarters in Delhi, India. Explore accolades received and milestones achieved by our signature projects."
        img="https://archdesignsstudio.com/assets/linkpreview.png"
      />
      <Header isWhite />
      <div className="milestones">
        <h1 className="heading-primary milestones__heading">
          <Link className="milestones__back" to={"/"}>
            &#8592;
          </Link>
          Milestones
        </h1>
        <div className="milestones--desktop">{desktopView}</div>
        <div className="milestones--mobile">{mobileView}</div>
      </div>
    </Layout>
  );
}

export default Milestones;
