import React from "react";
import "./style.scss";
import Proptypes from "prop-types";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "./aos.css";

function Milestone({ title, description, img, isGray }) {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  if (isGray) {
    return (
      <>
        <section className="milestone milestone__gray--desktop">
          <div className="row">
            <div className="col col-12 col-lg-7">
              <div className="milestone__rightsection">
                <img
                  alt="Mile Stone Image"
                  className="milestone__img"
                  src={img}
                ></img>
              </div>
            </div>
            <div className="col col-12 col-lg-5">
              <div
                className={`milestone__leftsection milestone__leftsection--gray`}
              >
                <h2 className="milestone__heading">{title}</h2>
                {/* <p className="milestone__description">{description}</p> */}
              </div>
            </div>
          </div>
        </section>
        <section className="milestone milestone__gray--mobile">
          <div className="row">
            <div className="col col-12 col-lg-5">
              <div
                className={`milestone__leftsection milestone__leftsection--gray`}
              >
                <h2 className="milestone__heading">{title}</h2>
                {/* <p className="milestone__description">{description}</p> */}
              </div>
            </div>

            <div className="col col-12 col-lg-7">
              <div className="milestone__rightsection">
                <img className="milestone__img" src={img}></img>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else
    return (
      <section className="milestone">
        <div className="row">
          <div className="col col-12 col-lg-5">
            <div className={`milestone__leftsection`}>
              <h2 className="milestone__heading">{title}</h2>
              {/* <p className="milestone__description">{description}</p> */}
            </div>
          </div>

          <div className="col col-12 col-lg-7">
            <div className="milestone__rightsection">
              <img
                alt="Mile Stone Image"
                className="milestone__img"
                data-aos="move-left"
                aos-duration="500"
                src={img}
              ></img>
            </div>
          </div>
        </div>
      </section>
    );
}
Milestone.defaultProps = {
  isGray: false,
};

Milestone.propTypes = {
  title: Proptypes.string.isRequired,
  description: Proptypes.string.isRequired,
  img: Proptypes.string.isRequired,
  isGray: Proptypes.bool,
};

export default Milestone;
